import { useAppSelector } from "./stateHooks";

export default function useLocationsEnabled() {
  const organization = useAppSelector((s) =>
    s.organization.organizationState.status === "succeeded"
      ? s.organization.organizationState.data
      : undefined,
  );

  return organization?.is_location_selection_enabled === false ? false : true;
}
