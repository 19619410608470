import { Button, Typography } from "@mui/material";
import type { B2bBillingUser } from "@trainwell/features/legacy";
import { useCallback, useMemo, useState, type FormEventHandler } from "react";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  cancelInvite,
  getInvites,
  resetCancelInviteState,
} from "src/redux/slices/invitesSlice";
import type { RootState } from "src/redux/stores/store";
import { FormModal } from "../Shared/Modals/Form";
import FormFields from "../Shared/Modals/Form/Fields";
import Table from "../Shared/Table";
import { TableState, type TableDelegateMethods } from "../Shared/Table/types";
import { InviteCellContent } from "./InviteCell";
import { type InviteTableColumn } from "./types";

const route = APP_ROUTES.INVITES;

const columns: InviteTableColumn[] = [
  { key: "name", title: "Name" },
  { key: "status", title: "Status" },
  { key: "date_created", title: "Invited" },
  { key: "date_cancelled", title: "Cancelled" },
];

function getTableState(
  state: RootState["invites"]["invitesState"],
  onTryAgain: () => void,
) {
  switch (state.status) {
    case "idle":
      return TableState.empty("No invites found.");
    case "loading":
      return TableState.loading("Fetching invites");
    case "succeeded":
      return state.data.data.length === 0
        ? TableState.empty("No invites found.")
        : TableState.succeeded(
            state.data.data,
            state.data.page,
            state.data.totalPages,
          );
    case "failed":
      return TableState.failed(state.error, onTryAgain);
  }
}

export function InvitesTable({ onCreate }: { onCreate: () => void }) {
  const invitesState = useAppSelector((state) => state.invites.invitesState);
  const cancelInviteState = useAppSelector(
    (state) => state.invites.cancelInviteState,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [confirmCancelUser, setConfirmCancelUser] =
    useState<B2bBillingUser | null>(null);

  const refreshInvites = useCallback(
    (page?: number) => {
      dispatch(getInvites({ page }));
    },
    [dispatch],
  );

  const tableState: TableState<B2bBillingUser> = useMemo(
    () =>
      getTableState(invitesState, () => {
        refreshInvites(
          invitesState.status === "succeeded"
            ? invitesState.data.page
            : undefined,
        );
      }),
    [invitesState, refreshInvites],
  );

  const handleSelectPage = useCallback(
    (page: number) => {
      refreshInvites(page);
    },
    [refreshInvites],
  );

  const delegateMethods: TableDelegateMethods<
    B2bBillingUser,
    InviteTableColumn
  > = useMemo(
    () => ({
      keyForRow: (item) => item._id,
      renderCell: (item, column) => (
        <InviteCellContent b2bUser={item} columnKey={column.key} />
      ),
      onClickRow: (item) => {
        setConfirmCancelUser(item);
      },
    }),
    [navigate],
  );
  const handleCloseConfirmCancel = useCallback(() => {
    dispatch(resetCancelInviteState());
    setConfirmCancelUser(null);
  }, [setConfirmCancelUser]);

  const handleSubmitCancel: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      if (!confirmCancelUser) return;
      dispatch(cancelInvite(confirmCancelUser));
    },
    [dispatch, confirmCancelUser],
  );

  return (
    <>
      <Table<B2bBillingUser, InviteTableColumn>
        headerConfig={{
          title: route.title,
          subtitle: route.description,
          rightChildren: (
            <>
              <Button
                variant="contained"
                sx={{ fontSize: "16px", height: "40px", pl: "20px" }}
                startIcon={
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-0.0078125 7.35938C-0.0078125 7.03125 0.106771 6.7526 0.335938 6.52344C0.570312 6.28906 0.851562 6.17188 1.17969 6.17188H5.51562V1.84375C5.51562 1.51562 5.63021 1.23698 5.85938 1.00781C6.08854 0.773438 6.36719 0.65625 6.69531 0.65625C7.02344 0.65625 7.30208 0.773438 7.53125 1.00781C7.76562 1.23698 7.88281 1.51562 7.88281 1.84375V6.17188H12.2188C12.5417 6.17188 12.8177 6.28906 13.0469 6.52344C13.2812 6.7526 13.3984 7.03125 13.3984 7.35938C13.3984 7.6875 13.2812 7.96875 13.0469 8.20312C12.8177 8.43229 12.5417 8.54688 12.2188 8.54688H7.88281V12.8828C7.88281 13.2057 7.76562 13.4818 7.53125 13.7109C7.30208 13.9453 7.02344 14.0625 6.69531 14.0625C6.36719 14.0625 6.08854 13.9453 5.85938 13.7109C5.63021 13.4818 5.51562 13.2057 5.51562 12.8828V8.54688H1.17969C0.851562 8.54688 0.570312 8.43229 0.335938 8.20312C0.106771 7.96875 -0.0078125 7.6875 -0.0078125 7.35938Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={onCreate}
              >
                Invite
              </Button>
            </>
          ),
        }}
        footerConfig={{
          onSelectPage: handleSelectPage,
        }}
        columns={columns}
        tableState={tableState}
        delegateMethods={delegateMethods}
      />

      <FormModal
        open={!!confirmCancelUser}
        title={"Revoke Invite"}
        ctaTitle={confirmCancelUser?.date_cancelled ? "Okay" : "Revoke"}
        successMessage="Invite revoked"
        isFormValid={true}
        networkingState={cancelInviteState}
        submitButtonProps={{
          color: confirmCancelUser?.date_cancelled ? "primary" : "error",
        }}
        leftActions={
          !confirmCancelUser?.date_cancelled && (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                color: confirmCancelUser?.date_cancelled
                  ? "primary"
                  : "primary",
              }}
              onClick={handleCloseConfirmCancel}
            >
              Nevermind
            </Button>
          )
        }
        onClose={handleCloseConfirmCancel}
        onSubmit={
          confirmCancelUser?.date_cancelled
            ? (e) => {
                e.preventDefault();
                handleCloseConfirmCancel();
              }
            : handleSubmitCancel
        }
      >
        <FormFields networkingState={cancelInviteState}>
          <Typography sx={{ textAlign: "center" }}>
            {confirmCancelUser?.date_cancelled
              ? "This invite was already revoked"
              : "Revoking this invite will prevent this client from signing up"}
          </Typography>
        </FormFields>
      </FormModal>
    </>
  );
}
