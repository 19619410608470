import { useLocation } from "react-router";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAppSelector } from "./stateHooks";

export interface Breadcrumb {
  path: string;
  title: string;
}

export default function useBreadcrumbs(): Breadcrumb[] {
  const location = useLocation();
  const clientStatesById = useAppSelector((s) => s.clients.clientStatesById);

  const breadcrumbs: Breadcrumb[] = [
    { path: APP_ROUTES.HOME.path(), title: APP_ROUTES.HOME.title },
  ];

  const pathComponents = location.pathname.split(/(?=\/[^/]+)/);

  if (pathComponents.length < 1) return breadcrumbs;

  if (pathComponents[0] === APP_ROUTES.CLIENTS.path()) {
    // Clients route.
    breadcrumbs.push({
      path: APP_ROUTES.CLIENTS.path(),
      title: APP_ROUTES.CLIENTS.title,
    });

    // Clients Detail Route
    if (pathComponents[1]) {
      const clientId = pathComponents[1].slice(1);
      const clientState = clientStatesById[clientId];

      const title =
        clientState?.summaryState.status === "succeeded"
          ? `${clientState.summaryState.data.first_name} ${clientState.summaryState.data.last_name}`
          : clientId;

      breadcrumbs.push({
        path: APP_ROUTES.CLIENTS.detail.path(clientId, false),
        title: title,
      });
    }
  }

  if (pathComponents[0] === APP_ROUTES.INVITES.path()) {
    // Invites route.
    breadcrumbs.push({
      path: APP_ROUTES.INVITES.path(),
      title: APP_ROUTES.INVITES.title,
    });
  }

  if (pathComponents[0] === APP_ROUTES.STAFF.path()) {
    // Staff route.
    breadcrumbs.push({
      path: APP_ROUTES.STAFF.path(),
      title: APP_ROUTES.STAFF.title,
    });
  }

  if (pathComponents[0] === APP_ROUTES.LOCATIONS.path()) {
    // Locations route.
    breadcrumbs.push({
      path: APP_ROUTES.LOCATIONS.path(),
      title: APP_ROUTES.LOCATIONS.title,
    });
  }

  return breadcrumbs;
}
