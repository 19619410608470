import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Organization } from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import { NetworkingState } from "src/types/NetworkingState";
import type { RootState } from "../stores/store";

export const getOrganization = createAsyncThunk(
  "organization/getOrganization",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const result = await api.organizations.getOrganization(
      state.deity.ghostMode.organizationId,
    );
    return result.organization;
  },
);

interface OrganizationSlice {
  organizationState: NetworkingState<Organization>;
}

// Define the initial state using that type
const initialState: OrganizationSlice = {
  organizationState: NetworkingState.idle(),
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetOrganization: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganization.pending, (state) => {
      state.organizationState = NetworkingState.loading();
    });
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      state.organizationState = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(getOrganization.rejected, (state, action) => {
      state.organizationState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
