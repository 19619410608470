import type { RootState } from "src/redux/stores/store";
import { TableState, type TableColumn } from "../Shared/Table/types";

export type LocationsTableColumn = TableColumn & {
  key: "name";
};

export const columns: LocationsTableColumn[] = [{ key: "name", title: "Name" }];

export function getTableState(
  state: RootState["locations"]["locationsState"],
  onTryAgain: () => void,
) {
  switch (state.status) {
    case "idle":
      return TableState.empty("No locations found.");
    case "loading":
      return TableState.loading("Fetching locations");
    case "succeeded":
      return state.data.length === 0
        ? TableState.empty("No locations found.")
        : TableState.succeeded(state.data);
    case "failed":
      return TableState.failed(state.error, onTryAgain);
  }
}
