import { type OrganizationLocation } from "@trainwell/features/legacy";
import { useState } from "react";
import { TablePageContainer } from "../Shared/Table/PageContainer";
import { LocationsTable } from "./LocationsTable";
import CreateLocationModal from "./Modals/CreateLocation";
import UpdateLocationModal from "./Modals/ManageLocation";

export function Locations() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingLocation, setEditingLocation] = useState<
    OrganizationLocation | undefined
  >(undefined);

  const handleStartEditing = (location: OrganizationLocation) => {
    handleStopCreating();
    setEditingLocation(location);
  };

  const handleStartCreating = () => {
    handleStopEditing();
    setIsCreating(true);
  };

  const handleStopCreating = () => {
    setIsCreating(false);
  };

  const handleStopEditing = () => {
    setEditingLocation(undefined);
  };

  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <LocationsTable
        onCreate={handleStartCreating}
        onEdit={handleStartEditing}
      />
      <CreateLocationModal
        key={isCreating.toString()}
        open={isCreating}
        onClose={handleStopCreating}
      />
      <UpdateLocationModal
        key={editingLocation?._id}
        location={editingLocation}
        onClose={handleStopEditing}
      />
    </TablePageContainer>
  );
}
