import { CssBaseline } from "@mui/material";
import { updateApiOptions } from "@trainwell/features";
import { SnackbarProvider } from "notistack";
import type { ReactNode } from "react";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { store } from "src/redux/stores/store";
import { AuthProvider } from "./AuthProvider";

updateApiOptions({
  prefixUrl: TRAINWELL_API_DOMAIN,
  headers: {
    "api-key": TRAINWELL_API_KEY,
  },
});

interface Props {
  children: ReactNode;
}

const persistor = persistStore(store);

export default function AppProviders({ children }: Props) {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <CssBaseline />
        <SnackbarProvider autoHideDuration={5000}>{children}</SnackbarProvider>
      </AuthProvider>
    </PersistGate>
  );
}
