import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import type { OrganizationLocation } from "@trainwell/features/legacy";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useAuth } from "src/providers/AuthProvider";
import { selectLocationAndFetchClients } from "src/redux/coordinator";
import { SelectableLocation } from "src/types/SelectableLocation";

const valueForOption = (selectableLocation: SelectableLocation) => {
  switch (selectableLocation.type) {
    case "none":
      return "none";
    case "all":
      return "all";
    case "location":
      return selectableLocation.location._id;
  }
};

const titleForOption = (selectableLocation: SelectableLocation) => {
  switch (selectableLocation.type) {
    case "none":
      return "Select a Location";
    case "all":
      return "All Locations";
    case "location":
      return selectableLocation.location.name;
  }
};

export default function SelectLocation() {
  const locationsState = useAppSelector(
    (state) => state.locations.locationsState,
  );
  const selectedLocation = useAppSelector(
    (state) => state.locations.selectedLocation,
  );
  const dispatch = useAppDispatch();

  const { role, loading: isAuthLoading } = useAuth();

  const locationOptions: SelectableLocation[] = useMemo(() => {
    if (locationsState.status !== "succeeded") return [];
    const options =
      role === "admin" || role === "deity" ? [SelectableLocation.all()] : [];
    options.push(
      ...locationsState.data.map((loc) => SelectableLocation.location(loc)),
    );
    return options;
  }, [locationsState, role]);

  const locationsById = useMemo(() => {
    if (locationsState.status !== "succeeded") return {};
    return locationsState.data.reduce(
      (acc: Record<string, OrganizationLocation>, location) => {
        acc[location._id] = location;
        return acc;
      },
      {},
    );
  }, [locationsState]);

  const getSelectableLocationForValue = useCallback(
    (value: string): SelectableLocation => {
      const location = locationsById[value];
      if (location) {
        return SelectableLocation.location(location);
      } else if (value === SelectableLocation.all().type) {
        return SelectableLocation.all();
      } else {
        return SelectableLocation.none();
      }
    },
    [locationsById],
  );

  if (isAuthLoading || locationsState.status === "loading")
    return <CircularProgress />;
  if (locationsState.status === "idle" || locationsState.status === "failed")
    return null;

  const handleSelectedLocationChange = async (event: SelectChangeEvent) => {
    const value = event.target.value;
    const selectedLocation = getSelectableLocationForValue(value);
    await dispatch(selectLocationAndFetchClients({ selectedLocation }));
  };

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: "200px",
        "& .MuiOutlinedInput-root": {
          fontSize: selectedLocation === null ? "16px" : "16px",
          color: selectedLocation === null ? "gray.500" : "gray.900",
          fontWeight: selectedLocation === null ? "400" : "500",
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px", // Adjust this value to change the border width
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px", // Ensure the border width remains consistent when focused
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.main",
          },
        },
      }}
      variant="outlined"
    >
      <Select
        id="select-location"
        value={valueForOption(selectedLocation)}
        onChange={handleSelectedLocationChange}
        displayEmpty
        renderValue={(value) =>
          titleForOption(getSelectableLocationForValue(value))
        }
        sx={{
          minWidth: "204px",
          height: "40px",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          "& .MuiSelect-select": {
            paddingLeft: "14px",
            paddingTop: "11px",
            paddingBottom: "9px",
          },
          "& .MuiSelect-icon": {
            color: "primary.main",
            inset: "12px 14px 12px auto",
          },
        }}
        IconComponent={(props) => (
          <Box
            {...props}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        )}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                fontSize: "16px",
                fontWeight: "400",
              },
              boxShadow:
                "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
              border: "1px solid #EAECF0",
            },
          },
        }}
      >
        {locationOptions.map((option) => (
          <MenuItem key={valueForOption(option)} value={valueForOption(option)}>
            {titleForOption(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
