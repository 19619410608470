import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { B2bBillingUser, PaginatedData } from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/redux/stores/store";
import { NetworkingState } from "src/types/NetworkingState";

export const getInvites = createAsyncThunk(
  "invites/getInvites",
  async (
    { page = 1 }: { page?: number; locationId?: string } = {},
    { getState },
  ) => {
    const state = getState() as RootState;
    const ghostOrganizationId = state.deity.ghostMode.organizationId;

    const result = await api.organizations.getInvites(
      page,
      undefined,
      ghostOrganizationId,
    );
    return result;
  },
);

export const cancelInvite = createAsyncThunk(
  "invites/cancelInvite",
  async (user: B2bBillingUser) => {
    const result = await api.organizations.cancelInvite(user._id);
    return result.user;
  },
);

interface InvitesState {
  invitesState: NetworkingState<PaginatedData<B2bBillingUser>>;
  cancelInviteState: NetworkingState<B2bBillingUser>;
}

// Define the initial state using that type
const initialState: InvitesState = {
  invitesState: NetworkingState.idle(),
  cancelInviteState: NetworkingState.idle(),
};

export const invitesSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {
    resetInvites: () => initialState,
    resetCancelInviteState: (state) => {
      state.cancelInviteState = initialState.cancelInviteState;
    },
  },
  extraReducers: (builder) => {
    // GET INVITES
    builder.addCase(getInvites.pending, (state) => {
      state.invitesState = NetworkingState.loading();
    });
    builder.addCase(getInvites.fulfilled, (state, action) => {
      state.invitesState = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(getInvites.rejected, (state, action) => {
      state.invitesState = NetworkingState.failed(action.error.message);
    });

    // CANCEL INVITES
    builder.addCase(cancelInvite.pending, (state) => {
      state.cancelInviteState = NetworkingState.loading();
    });
    builder.addCase(cancelInvite.fulfilled, (state, action) => {
      state.cancelInviteState = NetworkingState.succeeded(action.payload);
      if (state.invitesState.status === "succeeded") {
        state.invitesState = NetworkingState.succeeded({
          ...state.invitesState.data,
          data: state.invitesState.data.data.map((user) =>
            user._id === action.payload._id ? action.payload : user,
          ),
        });
      }
    });
    builder.addCase(cancelInvite.rejected, (state, action) => {
      state.cancelInviteState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetInvites, resetCancelInviteState } = invitesSlice.actions;

export default invitesSlice.reducer;
