import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";
import { ClientDetail } from "src/components/ClientDetail";
import { Clients } from "src/components/Clients";
import { Home } from "src/components/Home";
import { Invites } from "src/components/Invites";
import Layout from "src/components/Layout";
import { Locations } from "src/components/Locations";
import FinishSignInPage from "src/components/LoginPage/FinishSignInPage";
import SignInPage from "src/components/LoginPage/SignInPage";
import { NotFound } from "src/components/NotFound";
import { Staff } from "src/components/Staff";
import { APP_ROUTES } from "src/constants/AppRoutes";
import AppRoot from "./AppRoot";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <AppRoot />,
    children: [
      // Paths without layout.
      {
        path: APP_ROUTES.SIGN_IN.path(),
        element: <SignInPage />,
      },
      {
        path: APP_ROUTES.SIGN_IN.finish.path(),
        element: <FinishSignInPage />,
      },
      {
        path: APP_ROUTES.CLIENTS.detail.path(undefined, true),
        element: <ClientDetail isPrinting={true} />,
      },
      // Paths with layout.
      {
        element: <Layout />,
        children: [
          {
            path: APP_ROUTES.CLIENTS.path(),
            element: <Clients />,
          },
          {
            path: APP_ROUTES.INVITES.path(),
            element: <Invites />,
          },
          {
            path: APP_ROUTES.CLIENTS.detail.path(undefined, false),
            element: <ClientDetail />,
          },
          {
            path: APP_ROUTES.STAFF.path(),
            element: <Staff />,
          },
          {
            path: APP_ROUTES.LOCATIONS.path(),
            element: <Locations />,
          },
          {
            index: true,
            element: <Home />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ],
  },
]);

export function AppRoutes() {
  return <RouterProvider router={router} />;
}
