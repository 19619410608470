import { Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import useShouldRefreshData from "src/hooks/useShouldRefreshData";
import {
  getClientDetails,
  getClientSummary,
} from "src/redux/slices/clientsSlice";
import { ClientDetailErrorBody } from "./ClientDetailErrorBody";
import { ClientDetailLoadingBody } from "./ClientDetailLoadingBody";
import { ClientDetailSuccessBody } from "./ClientDetailSuccessBody";

export function ClientDetail({ isPrinting = false }: { isPrinting?: boolean }) {
  const { clientId = "" } = useParams();
  const dispatch = useAppDispatch();
  const clientStates = useAppSelector(
    (state) => state.clients.clientStatesById[clientId],
  );

  const refreshClientSummary = useCallback(() => {
    dispatch(getClientSummary(clientId));
  }, [clientId, dispatch]);

  const refreshClientDetails = useCallback(() => {
    dispatch(getClientDetails(clientId));
  }, [clientId, dispatch]);

  const shouldRefreshData = useShouldRefreshData({
    shouldPreventRefresh: () => clientId === "",
  });

  // Kick off a fetch request for client summary if needed.
  useEffect(() => {
    if (!shouldRefreshData(clientStates?.summaryState)) return;
    refreshClientSummary();
  }, [clientStates?.summaryState, shouldRefreshData, refreshClientSummary]);

  // Kick off a fetch request for client details if needed.
  useEffect(() => {
    if (!shouldRefreshData(clientStates?.detailsState)) return;
    refreshClientDetails();
  }, [clientStates?.detailsState, shouldRefreshData, refreshClientDetails]);

  useEffect(() => {
    if (!isPrinting) return;
    window.print();

    const handleAfterPrint = () => {
      window.close();
    };

    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="column"
      gap="72px"
      sx={{
        margin: isPrinting ? "0px auto auto auto" : "64px auto 64px auto",
        width: isPrinting ? "fit-content" : "100%",
        maxWidth: isPrinting ? "720px" : undefined,
        height: isPrinting ? "auto" : "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        filter: isPrinting ? "grayscale(100%)" : "none",
      }}
    >
      {clientStates?.summaryState.status === "succeeded" && (
        <ClientDetailSuccessBody
          summary={clientStates.summaryState.data}
          details={
            clientStates.detailsState?.status === "succeeded"
              ? clientStates.detailsState.data
              : undefined
          }
          isPrinting={isPrinting}
        />
      )}
      {(!clientStates?.summaryState.status ||
        clientStates?.summaryState.status === "idle" ||
        clientStates.summaryState.status === "loading") && (
        <ClientDetailLoadingBody />
      )}
      {clientStates?.summaryState.status === "failed" && (
        <ClientDetailErrorBody
          error={clientStates.summaryState.error}
          onTryAgain={refreshClientSummary}
        />
      )}
    </Stack>
  );
}
