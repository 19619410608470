import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  type ContainerProps,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { getAuth, signOut } from "firebase/auth";
import { useMemo } from "react";
import { Outlet, useNavigate } from "react-router";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAppSelector } from "src/hooks/stateHooks";
import useBreadcrumbs from "src/hooks/useBreadcrumbs";
import useLocationsEnabled from "src/hooks/useLocationsEnabled";
import usePrimaryRoutesForRole from "src/hooks/usePrimaryRoutesForRole";
import { useAuth } from "src/providers/AuthProvider";
import Breadcrumbs from "../Breadcrumbs";
import GhostModeButton from "./Ghost";
import SelectLocation from "./SelectLocation";

const NAV_BAR_HEIGHT = 72;
const NAV_BAR_HEIGHT_PX = `${NAV_BAR_HEIGHT}px`;

const BREADCRUMB_BAR_HEIGHT = 40;
const BREADCRUMB_BAR_HEIGHT_PX = `${BREADCRUMB_BAR_HEIGHT}px`;

const ContentContainer = ({ children, sx, ...rest }: ContainerProps) => (
  <Container
    maxWidth="lg"
    sx={{ px: { xs: "24px", sm: "24px", md: "24px" }, ...sx }}
    {...rest}
  >
    {children}
  </Container>
);

export default function Layout() {
  const navigate = useNavigate();
  const organizationState = useAppSelector(
    (state) => state.organization.organizationState,
  );
  const routes = usePrimaryRoutesForRole();
  const breadcrumbs = useBreadcrumbs();
  const locationsEnabled = useLocationsEnabled();
  const showBreadcrumbBar = useMemo(
    () => breadcrumbs.length > 1,
    [breadcrumbs],
  );
  const { role } = useAuth();

  function handleLogout() {
    navigate(APP_ROUTES.SIGN_IN.path());

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Auth: sign out success");

        localStorage.clear();
        sessionStorage.clear();
      })
      .catch((error: unknown) => {
        console.error("Auth: sign out failed");
        Sentry.captureException(error);
      });
  }

  const topContentInsetPx = useMemo(() => {
    let totalAppBarHeight = NAV_BAR_HEIGHT;
    if (showBreadcrumbBar) totalAppBarHeight += BREADCRUMB_BAR_HEIGHT;
    return `${totalAppBarHeight}px`;
  }, [showBreadcrumbBar]);

  return (
    <Box
      sx={{
        position: "absolute",
        inset: `${topContentInsetPx} 0px 0px 0px`,
        maxWidth: "100vw",
        minHeight: `calc(100vh - ${topContentInsetPx})`,
        scrollbarGutter: "stable",
        overflowY: "scroll",
      }}
    >
      {/* Nav Bar */}
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: NAV_BAR_HEIGHT_PX,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
          scrollbarGutter: "stable",
          overflowY: "scroll",
        }}
        elevation={0}
      >
        <ContentContainer>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" gap="24px" sx={{ alignItems: "center" }}>
              <Box
                component="img"
                onClick={() => navigate(APP_ROUTES.HOME.path())}
                src={
                  organizationState.status === "succeeded"
                    ? organizationState.data?.logo_url
                    : ""
                }
                sx={{ height: "20px", mb: "2px", cursor: "pointer" }}
              />
              {routes.length > 0 && (
                <>
                  <Divider flexItem orientation="vertical" />
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    {routes.map((route) => (
                      <Link
                        key={route.path()}
                        href={route.path()}
                        sx={{
                          textDecoration: "none",
                          color: "gray.500",
                          fontSize: "14px",
                          lineHeight: "100%",
                          ":hover": {
                            color: "gray.700",
                          },
                        }}
                      >
                        {route.title}
                      </Link>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                gap: "16px",
              }}
            >
              {locationsEnabled && <SelectLocation />}
              {role === "deity" && <GhostModeButton />}
              <Button
                variant="contained"
                sx={{ fontSize: "16px" }}
                onClick={handleLogout}
              >
                Log Out
              </Button>
            </Stack>
          </Stack>
        </ContentContainer>
      </AppBar>

      {/* Breadcrumb Bar */}
      {showBreadcrumbBar && (
        <AppBar
          position="fixed"
          sx={{
            inset: `${NAV_BAR_HEIGHT_PX} 0px auto 0px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: BREADCRUMB_BAR_HEIGHT_PX,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderBottom: 1,
            borderColor: "divider",
            scrollbarGutter: "stable",
            overflowY: "scroll",
          }}
          elevation={0}
        >
          <ContentContainer>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </ContentContainer>
        </AppBar>
      )}

      {/* Page Content */}
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Box>
  );
}
