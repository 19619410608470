const HOME = {
  title: "Home",
  description: "Home",
  iconPath: "/assets/home.svg",
  path: () => "/",
};

const SIGN_IN = {
  path: () => "/sign-in",
  finish: {
    path: () => "/sign-in/finish",
  },
};

const CLIENTS = {
  title: "Clients",
  description: "Manage active and cancelled clients in your organization.",
  iconPath: "/assets/patient.svg",
  path: () => "/clients",
  detail: {
    path: (clientId = ":clientId", print = false) =>
      `/clients/${clientId}${print ? "/print" : ""}`,
  },
};

const INVITES = {
  title: "Invites",
  description: "Manage sent and revoked invites in your organization.",
  iconPath: "/assets/invite.svg",
  path: () => "/invites",
};

const STAFF = {
  title: "Staff",
  description: "Manage staff in your organization",
  iconPath: "/assets/user.svg",
  path: () => "/users",
};

const LOCATIONS = {
  title: "Locations",
  description: "View and manage locations in your organization",
  iconPath: "/assets/location.svg",
  path: () => "/locations",
};

export const APP_ROUTES = {
  HOME,
  SIGN_IN,
  CLIENTS,
  INVITES,
  STAFF,
  LOCATIONS,
};
