import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box, Button, Typography } from "@mui/material";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import EmailDialog from "./EmailDialog";

export default function SignInPage() {
  const [emailDialodOpen, setEmailDialogOpen] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    // @ts-expect-error
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("Captcha solved");
        // onSignInSubmit();
      },
    });
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          minHeight: "300px",
        }}
      >
        <Box
          component={"img"}
          sx={{ width: "336px", height: "90px", mb: 1 }}
          alt="Logo"
          src="/assets/trainwell-wordmark.svg"
        />
        <Typography variant="h6" sx={{ mb: 8 }}>
          Partner Organization Dashboard
        </Typography>
        <Button
          id="sign-in-button"
          onClick={() => {
            setEmailDialogOpen(true);
          }}
          sx={{ mb: 2 }}
          startIcon={<EmailRoundedIcon />}
        >
          Sign in with email
        </Button>
        <EmailDialog
          open={emailDialodOpen}
          onClose={() => {
            setEmailDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
}
