import { Stack } from "@mui/material";
import usePrimaryRoutesForRole from "src/hooks/usePrimaryRoutesForRole";
import { TablePageContainer } from "../Shared/Table/PageContainer";
import { Greeting } from "./Greeting";
import RouteCard from "./RouteCard";

export function Home() {
  const primaryRoutes = usePrimaryRoutesForRole();
  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px", gap: "32px" }}>
      <Greeting />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          flexGrow: 1,
          flexWrap: "wrap",
          gap: "20px",
          "& > *": {
            flexBasis: "calc(33% - 10px)",
            flexGrow: 0,
            flexShrink: 0,
            minWidth: "200px",
            maxWidth: "calc(50% - 10px)",
          },
        }}
      >
        {primaryRoutes.map((route) => (
          <RouteCard
            key={route.path()}
            title={route.title}
            subtitle={route.description}
            path={route.path()}
            iconPath={route.iconPath}
          />
        ))}
      </Stack>
    </TablePageContainer>
  );
}
