import { createAsyncThunk } from "@reduxjs/toolkit";
import type { SelectableLocation } from "src/types/SelectableLocation";
import { getClientSummaries, resetClients } from "./slices/clientsSlice";
import { getInvites, resetInvites } from "./slices/invitesSlice";
import {
  getLocations,
  resetLocations,
  selectLocation,
} from "./slices/locationsSlice";
import { getOrganization, resetOrganization } from "./slices/organizationSlice";
import { getCurrentUser, getUsers, resetUser } from "./slices/userSlice";

export const resetAppData = createAsyncThunk(
  "app/resetAppData",
  async (_, { dispatch }) => {
    dispatch(resetUser());
    dispatch(resetOrganization());
    dispatch(resetLocations());
    dispatch(resetClients());
    dispatch(resetInvites());
  },
);

export const refreshAppData = createAsyncThunk(
  "app/refreshAppData",
  async (_, { dispatch }) => {
    // These can run concurrently.
    await Promise.all([
      dispatch(getCurrentUser()).unwrap(),
      dispatch(getOrganization()).unwrap(),
      dispatch(getUsers({ page: 1 })).unwrap(),
    ]);

    // These must run serially, order is important.
    await dispatch(getLocations()).unwrap();
    await dispatch(getClientSummaries({ page: 1 })).unwrap();
    await dispatch(getInvites({ page: 1 })).unwrap();
  },
);

export const selectLocationAndFetchClients = createAsyncThunk(
  "app/selectLocation",
  async (
    { selectedLocation }: { selectedLocation: SelectableLocation },
    { dispatch },
  ) => {
    dispatch(selectLocation(selectedLocation));
    await dispatch(
      getClientSummaries({
        page: 1,
        locationId:
          selectedLocation.type === "location"
            ? selectedLocation.location._id
            : undefined,
      }),
    );
  },
);
