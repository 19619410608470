import { useMemo } from "react";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAuth, type AuthRole } from "src/providers/AuthProvider";
import { useAppSelector } from "./stateHooks";
import useLocationsEnabled from "./useLocationsEnabled";

type AppRoute = (typeof APP_ROUTES)["HOME"];

export default function usePrimaryRoutesForRole() {
  const { role } = useAuth();
  const locationsEnabled = useLocationsEnabled();

  const organization = useAppSelector((s) =>
    s.organization.organizationState.status === "succeeded"
      ? s.organization.organizationState.data
      : undefined,
  );

  const routesByRole: Record<AuthRole, AppRoute[]> = useMemo(() => {
    const memberRoutes = [APP_ROUTES.CLIENTS];
    const adminRoutes = [
      ...memberRoutes,
      APP_ROUTES.INVITES,
      APP_ROUTES.STAFF,
      ...(locationsEnabled ? [APP_ROUTES.LOCATIONS] : []),
    ];
    const deityRoutes = [...adminRoutes];
    return {
      member: memberRoutes,
      admin: adminRoutes,
      deity: deityRoutes,
    };
  }, [role, organization]);

  return routesByRole[role ?? "member"];
}
