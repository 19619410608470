import { type OrganizationUser } from "@trainwell/features/legacy";
import { useState } from "react";
import { TablePageContainer } from "../Shared/Table/PageContainer";
import CreateUserModal from "./Modals/CreateUser";
import ManageUserModal from "./Modals/ManageUser";
import { StaffTable } from "./StaffTable";

export function Staff() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingUser, setEditingUser] = useState<OrganizationUser | undefined>(
    undefined,
  );

  const handleStartEditing = (user: OrganizationUser) => {
    handleStopCreating();
    setEditingUser(user);
  };

  const handleStartCreating = () => {
    handleStopEditing();
    setIsCreating(true);
  };

  const handleStopCreating = () => {
    setIsCreating(false);
  };

  const handleStopEditing = () => {
    setEditingUser(undefined);
  };

  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <StaffTable onCreate={handleStartCreating} onEdit={handleStartEditing} />
      <CreateUserModal
        key={isCreating.toString()}
        open={isCreating}
        onClose={handleStopCreating}
      />
      <ManageUserModal
        key={editingUser?._id}
        user={editingUser}
        onClose={handleStopEditing}
      />
    </TablePageContainer>
  );
}
