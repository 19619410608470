import { MenuItem, type SelectProps } from "@mui/material";
import type { OrganizationLocation } from "@trainwell/features/legacy";
import { useMemo } from "react";
import LocationChipStack from "src/components/Shared/LocationChipStack";
import { useAppSelector } from "src/hooks/stateHooks";
import Select from "./index";

export default function MultiSelectLocations({
  value,
  ...rest
}: SelectProps<OrganizationLocation["_id"][]>) {
  const locationsState = useAppSelector(
    (state) => state.locations.locationsState,
  );

  const locations: OrganizationLocation[] = useMemo(() => {
    if (locationsState.status !== "succeeded") return [];
    return locationsState.data;
  }, [locationsState]);

  return (
    <Select
      value={value}
      label={"Locations"}
      renderValue={(value) =>
        value.length <= 0 ? null : <LocationChipStack locationIds={value} />
      }
      multiple
      {...rest}
    >
      {locations.map((loc) => (
        <MenuItem key={loc._id} value={loc._id}>
          {loc.name}
        </MenuItem>
      ))}
    </Select>
  );
}
