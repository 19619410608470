import type { OrganizationUser } from "@trainwell/features/legacy";
import { type FormEventHandler } from "react";
import MultiSelectLocations from "src/components/Shared/Fields/Select/MultiSelectLocation";
import { RoleSelect } from "src/components/Shared/Fields/Select/Role";
import { EmailTextField } from "src/components/Shared/Fields/Text/Email";
import { NameTextField } from "src/components/Shared/Fields/Text/Name";
import { FormModal } from "src/components/Shared/Modals/Form";
import FormFields from "src/components/Shared/Modals/Form/Fields";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { createUser, resetCreateUserState } from "src/redux/slices/userSlice";
import useUserForm from "./useUserForm";

export default function CreateUserModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const locationsState = useAppSelector(
    (state) => state.locations.locationsState,
  );
  const createUserState = useAppSelector((state) => state.user.createUserState);
  const dispatch = useAppDispatch();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    locationIds,
    setLocationIds,
    role,
    setRole,
    isFormValid,
  } = useUserForm();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const processedLocationIds =
      role === "admin" && locationsState.status === "succeeded"
        ? locationsState.data.map((l) => l._id)
        : locationIds;

    dispatch(
      createUser({
        email,
        first_name: firstName,
        last_name: lastName,
        locations: processedLocationIds,
        auth: { role: role },
      }),
    );
  };

  const handleClose = () => {
    onClose();
    dispatch(resetCreateUserState());
  };

  return (
    <FormModal
      open={open}
      title="Create Staff Member"
      ctaTitle="Create"
      successMessage="Staff member created successfully."
      isFormValid={isFormValid}
      networkingState={createUserState}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <FormFields networkingState={createUserState}>
        <EmailTextField
          autoFocus
          id="email"
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          disabled={createUserState.status === "loading"}
        />
        <NameTextField
          id="firstName"
          label="First Name"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          required
          disabled={createUserState.status === "loading"}
        />
        <NameTextField
          id="lastName"
          label="Last Name"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          required
          disabled={createUserState.status === "loading"}
        />
        <RoleSelect
          id="role"
          value={role}
          onChange={(e) => {
            setRole(e.target.value as OrganizationUser["auth"]["role"]);
          }}
          required
          disabled={createUserState.status === "loading"}
        />
        {role === "member" && (
          <MultiSelectLocations
            id="Locations"
            label="Locations"
            value={locationIds}
            onChange={(e) => {
              setLocationIds(e.target.value as string[]);
            }}
            disabled={createUserState.status === "loading"}
          />
        )}
      </FormFields>
    </FormModal>
  );
}
