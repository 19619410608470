import { Stack } from "@mui/material";
import type {
  OrgClientDetails,
  OrgClientSummary,
} from "@trainwell/features/legacy";
import {
  ClientNutritionConsistencyCellContent,
  ClientWorkoutConsistencyCellContent,
} from "../Shared/ClientConsistencyCellContent";
import { ClientMovementStreakCellContent } from "../Shared/ClientMovementStreakCellContent";
import { ClientStatusCellContent } from "../Shared/ClientStatusCellContent";
import { ClientDetailContact } from "./ClientDetailContact";
import { ClientDetailHeader } from "./ClientDetailHeader";
import { ClientDetailSummaryCard } from "./ClientDetailSummaryCard";

export const ClientDetailSuccessBody = ({
  summary,
  details,
  isPrinting,
}: {
  summary: OrgClientSummary;
  details?: OrgClientDetails;
  isPrinting: boolean;
}) => (
  <>
    <ClientDetailHeader summary={summary} isPrinting={isPrinting} />
    <Stack
      direction="row"
      sx={{
        width: "100%",
        gap: "96px",
      }}
    >
      <ClientDetailContact summary={summary} details={details} />
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          flexWrap: "wrap",
          gap: "20px",
          "& > *": {
            flexBasis: "calc(50% - 10px)",
            flexGrow: 1,
            minWidth: "200px",
          },
        }}
      >
        <ClientDetailSummaryCard title="Status">
          <ClientStatusCellContent summary={summary} />
        </ClientDetailSummaryCard>
        <ClientDetailSummaryCard title="Movement Streak">
          <ClientMovementStreakCellContent summary={summary} />
        </ClientDetailSummaryCard>
        <ClientDetailSummaryCard title="Workout Consistency">
          <ClientWorkoutConsistencyCellContent summary={summary} />
        </ClientDetailSummaryCard>
        <ClientDetailSummaryCard title="Nutrition Consistency">
          <ClientNutritionConsistencyCellContent summary={summary} />
        </ClientDetailSummaryCard>
      </Stack>
    </Stack>
  </>
);
