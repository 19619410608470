import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import type {
  OrganizationLocation,
  OrganizationLocationCreate,
  OrganizationLocationUpdate,
} from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import { NetworkingState } from "src/types/NetworkingState";
import { SelectableLocation } from "src/types/SelectableLocation";
import type { RootState } from "../stores/store";

export const getLocations = createAsyncThunk(
  "locations/getLocations",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const currentUserOrganizationId =
      state.user.currentUserState.status === "succeeded"
        ? state.user.currentUserState.data.organization_id
        : "";
    const result = await api.organizations.getLocations(
      state.deity.ghostMode.organizationId ?? currentUserOrganizationId,
    );
    return result.locations;
  },
);

export const createLocation = createAsyncThunk(
  "locations/createLocation",
  async (
    createLocation: OrganizationLocationCreate,
    { dispatch, getState },
  ) => {
    // Create the location
    const state = getState() as RootState;
    const { location } = await api.organizations.createLocation(
      state.deity.ghostMode.organizationId,
      createLocation,
    );
    // Trigger refresh of locations.
    dispatch(getLocations());
    return location;
  },
);

export const updateLocation = createAsyncThunk(
  "locations/updateLocation",
  async (
    {
      locationId,
      updates,
    }: {
      locationId: string;
      updates: OrganizationLocationUpdate;
    },
    { dispatch },
  ) => {
    // Update the location
    await api.organizations.updateLocation(locationId, updates);
    // Trigger refresh of locations.
    dispatch(getLocations());
  },
);

export const deleteLocation = createAsyncThunk(
  "locations/deleteLocation",
  async ({ locationId }: { locationId: string }, { dispatch }) => {
    // Delete the location
    await api.organizations.deleteLocation(locationId);
    // Trigger refresh of locations.
    dispatch(getLocations());
  },
);

interface LocationState {
  locationsState: NetworkingState<OrganizationLocation[]>;
  locationStatesById: Record<string, NetworkingState<OrganizationLocation>>;
  selectedLocation: SelectableLocation;
  createLocationState: NetworkingState<OrganizationLocation>;
  updateLocationState: NetworkingState<undefined>;
  deleteLocationState: NetworkingState<undefined>;
}

// Define the initial state using that type
const initialState: LocationState = {
  locationsState: NetworkingState.idle(),
  locationStatesById: {},
  selectedLocation: SelectableLocation.none(),
  createLocationState: NetworkingState.idle(),
  updateLocationState: NetworkingState.idle(),
  deleteLocationState: NetworkingState.idle(),
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    resetLocations: () => initialState,
    selectLocation: (state, action: PayloadAction<SelectableLocation>) => {
      state.selectedLocation = action.payload;
    },
    resetCreateLocationState: (state) => {
      state.createLocationState = initialState.createLocationState;
    },
    resetUpdateLocationState: (state) => {
      state.updateLocationState = initialState.updateLocationState;
    },
    resetDeleteLocationState: (state) => {
      state.deleteLocationState = initialState.deleteLocationState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.locationsState = NetworkingState.loading();
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.locationsState = NetworkingState.succeeded(action.payload);
      state.locationStatesById = action.payload.reduce(
        (acc: LocationState["locationStatesById"], location) => {
          acc[location._id] = NetworkingState.succeeded(location);
          return acc;
        },
        {},
      );
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.locationsState = NetworkingState.failed(action.error.message);
    });

    // CREATE LOCATION
    builder.addCase(createLocation.pending, (state) => {
      state.createLocationState = NetworkingState.loading();
    });
    builder.addCase(createLocation.fulfilled, (state, action) => {
      state.createLocationState = NetworkingState.succeeded(action.payload);
      state.locationStatesById[action.payload._id] = NetworkingState.succeeded(
        action.payload,
      );
    });
    builder.addCase(createLocation.rejected, (state, action) => {
      state.createLocationState = NetworkingState.failed(action.error.message);
    });

    // UPDATE LOCATION
    builder.addCase(updateLocation.pending, (state) => {
      state.updateLocationState = NetworkingState.loading();
    });
    builder.addCase(updateLocation.fulfilled, (state) => {
      state.updateLocationState = NetworkingState.succeeded(undefined);
    });
    builder.addCase(updateLocation.rejected, (state, action) => {
      state.updateLocationState = NetworkingState.failed(action.error.message);
    });

    // DELETE LOCATION
    builder.addCase(deleteLocation.pending, (state) => {
      state.deleteLocationState = NetworkingState.loading();
    });
    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.locationStatesById[action.meta.arg.locationId];
      state.deleteLocationState = NetworkingState.succeeded(undefined);
    });
    builder.addCase(deleteLocation.rejected, (state, action) => {
      state.deleteLocationState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetLocations,
  selectLocation,
  resetCreateLocationState,
  resetUpdateLocationState,
  resetDeleteLocationState,
} = locationsSlice.actions;

export default locationsSlice.reducer;
