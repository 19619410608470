import type { OrganizationLocation } from "@trainwell/features/legacy";

export type SelectableLocation =
  | { type: "none" }
  | { type: "all" }
  | { type: "location"; location: OrganizationLocation };

export const SelectableLocation = {
  none(): SelectableLocation {
    return { type: "none" };
  },

  all(): SelectableLocation {
    return { type: "all" };
  },

  location(location: OrganizationLocation): SelectableLocation {
    return { type: "location", location };
  },
};
