import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import clientsReducer from "src/redux/slices/clientsSlice";
import deityReducer from "src/redux/slices/deitySlice";
import invitesReducer from "src/redux/slices/invitesSlice";
import locationsReducer from "src/redux/slices/locationsSlice";
import organizationReducer from "src/redux/slices/organizationSlice";
import userReducer from "src/redux/slices/userSlice";

const persistConfig = (key: string) => ({
  key: key,
  version: 8,
  storage: storage,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: false,
});

const rootReducer = combineReducers({
  user: persistReducer(persistConfig("userReducer"), userReducer),
  locations: persistReducer(
    persistConfig("locationsReducer"),
    locationsReducer,
  ),
  organization: organizationReducer,
  clients: clientsReducer,
  invites: invitesReducer,
  deity: deityReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
