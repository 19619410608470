import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { NetworkingState } from "src/types/NetworkingState";
import { refreshAppData } from "../coordinator";
import type { RootState } from "../stores/store";

export const resetGhostMode = createAsyncThunk(
  "deity/resetGhostMode",
  async (_, { dispatch }) => {
    dispatch(resetGhostModeState());
    await dispatch(refreshAppData());
  },
);

export const updateGhostMode = createAsyncThunk(
  "deity/updateGhostMode",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const organizationId = state.deity.ghostMode.organizationId;
    const result = await dispatch(refreshAppData());

    if (result.meta.requestStatus === "rejected") {
      const error = (result as any).error.message ?? "Something went wrong.";
      throw new Error(error);
    }
    return organizationId;
  },
);

interface DeitySlice {
  ghostMode: {
    organizationId?: string;
    state: NetworkingState<string | undefined>;
  };
}

// Define the initial state using that type
const initialState: DeitySlice = {
  ghostMode: {
    state: NetworkingState.idle(),
  },
};

export const deitySlice = createSlice({
  name: "deity",
  initialState,
  reducers: {
    resetDeityState: () => initialState,
    resetGhostModeState: (state) => {
      state.ghostMode = initialState.ghostMode;
    },
    setGhostModeOrganizationId: (state, action: PayloadAction<string>) => {
      state.ghostMode.organizationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateGhostMode.pending, (state) => {
      state.ghostMode.state = NetworkingState.loading();
    });
    builder.addCase(updateGhostMode.fulfilled, (state, action) => {
      state.ghostMode.state = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(updateGhostMode.rejected, (state, action) => {
      state.ghostMode.state = NetworkingState.failed(action.error.message);
    });

    builder.addCase(resetGhostMode.pending, (state) => {
      state.ghostMode.state = NetworkingState.loading();
    });
    builder.addCase(resetGhostMode.fulfilled, (state) => {
      state.ghostMode.state = NetworkingState.idle();
    });
    builder.addCase(resetGhostMode.rejected, (state, action) => {
      state.ghostMode.state = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetDeityState,
  resetGhostModeState,
  setGhostModeOrganizationId,
} = deitySlice.actions;

export default deitySlice.reducer;
