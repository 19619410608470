import { type OrgClientSummary } from "@trainwell/features/legacy";
import { type JSX } from "react";
import {
  ClientNutritionConsistencyCellContent,
  ClientWorkoutConsistencyCellContent,
} from "../Shared/ClientConsistencyCellContent";
import { ClientMovementStreakCellContent } from "../Shared/ClientMovementStreakCellContent";
import { ClientNameCellContent } from "../Shared/ClientNameCellContent";
import { ClientStatusCellContent } from "../Shared/ClientStatusCellContent";
import { type ClientTableColumn } from "./types";

export const ClientCellContent = ({
  columnKey,
  summary,
}: {
  columnKey: ClientTableColumn["key"];
  summary: OrgClientSummary;
}): JSX.Element | null => {
  switch (columnKey) {
    case "name":
      return <ClientNameCellContent summary={summary} />;
    case "status":
      return <ClientStatusCellContent summary={summary} />;
    case "nutrition_consistency":
      return <ClientNutritionConsistencyCellContent summary={summary} />;
    case "workout_consistency":
      return <ClientWorkoutConsistencyCellContent summary={summary} />;
    case "movement_streak":
      return <ClientMovementStreakCellContent summary={summary} />;
  }
};
