import { Stack, type StackProps } from "@mui/material";
import type { OrganizationLocation } from "@trainwell/features/legacy";
import { useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import type { AuthRole } from "src/providers/AuthProvider";
import { Chip } from "./Chip";

export default function LocationChipStack({
  locationIds,
  role,
  ...rest
}: StackProps & {
  locationIds: OrganizationLocation["_id"][];
  role?: AuthRole;
}) {
  const locationStatesById = useAppSelector(
    (state) => state.locations.locationStatesById,
  );
  const showAllLocations = useMemo(
    () => role === "admin" || role === "deity",
    [role],
  );
  return (
    <Stack direction="row" sx={{ flexWrap: "wrap", gap: "4px" }} {...rest}>
      {showAllLocations && (
        <Chip chipStyle="primary" sx={{ whiteSpace: "nowrap" }}>
          All Locations
        </Chip>
      )}
      {!showAllLocations &&
        locationIds.map((locId) => {
          const locationState = locationStatesById[locId];
          switch (locationState?.status) {
            case undefined:
              return null;
            case "idle":
              return null;
            case "succeeded":
              return (
                <Chip
                  key={locId}
                  chipStyle="neutral"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {locationState.data.name}
                </Chip>
              );
            case "loading":
              return (
                <Chip
                  key={locId}
                  chipStyle="neutral"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  ...
                </Chip>
              );
            case "failed":
              return (
                <Chip
                  key={locId}
                  chipStyle="neutral"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Error
                </Chip>
              );
          }
        })}
    </Stack>
  );
}
