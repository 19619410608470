import { Typography } from "@mui/material";
import { type B2bBillingUser } from "@trainwell/features/legacy";
import { type JSX } from "react";
import { getFormattedDateString } from "src/lib/utils";
import { Chip } from "../Shared/Chip";
import { type InviteTableColumn } from "./types";

export const InviteCellContent = ({
  columnKey,
  b2bUser,
}: {
  columnKey: InviteTableColumn["key"];
  b2bUser: B2bBillingUser;
}): JSX.Element | null => {
  switch (columnKey) {
    case "name":
      return <>{`${b2bUser.first_name} ${b2bUser.last_name}`}</>;
    case "date_created":
      return <>{getFormattedDateString(b2bUser.date_created)}</>;
    case "date_cancelled":
      return (
        <>
          {b2bUser.date_cancelled
            ? getFormattedDateString(b2bUser.date_cancelled)
            : "N/A"}
        </>
      );
    case "status":
      return (
        <Chip chipStyle={!b2bUser.date_cancelled ? "success" : "neutral"}>
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", height: "min-content" }}
          >
            {!b2bUser.date_cancelled ? "Sent" : "Revoked"}
          </Typography>
        </Chip>
      );
  }
};
