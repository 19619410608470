import { useState } from "react";
import InviteClientModal from "../Clients/Modals/InviteClient";
import { TablePageContainer } from "../Shared/Table/PageContainer";
import { InvitesTable } from "./InvitesTable";

export function Invites() {
  const [isCreating, setIsCreating] = useState(false);

  const handleStartCreating = () => {
    setIsCreating(true);
  };

  const handleStopCreating = () => {
    setIsCreating(false);
  };

  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <InvitesTable onCreate={handleStartCreating} />
      <InviteClientModal
        key={isCreating.toString()}
        open={isCreating}
        onClose={handleStopCreating}
      />
    </TablePageContainer>
  );
}
